import React, { useState, useEffect} from "react"; 
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import secureStorage from "./../../config/encrypt";
// import { UserActions } from "../../store/action";
import VerifyOtp from './VerifyOtp';
import Captcha from '../../view/common/Captcha';   
import { useSearchParams } from 'react-router-dom';
import FullPageLoader from '../common/FullPageLoader';
import { toast } from 'react-toastify';
import { executeGraphQLMutation ,executeGraphQLQuery} from '../../common/executeGraphQLQuery';
import { Login_User,GOOGLE_EMAIL } from "../../services/login.gql";
import { useApolloClient } from "@apollo/client"; 
import { useSelector, useDispatch } from "react-redux";
import { loggedIn } from '../../store/reducers/userReducer';
import GoogleLogin from "./GoogleLogin"; 

function EmailUser(props) {
// eslint-disable-next-line
    const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);

    const dispatch = useDispatch();
    const client = useApolloClient();
    const captchaData = useSelector((state) => { 
        return state.user;
    })
    
    const { t } = useTranslation('common');
    const [loading, setLoading] = useState(false);
    const [LoginData, setLoginData] = useState(null);
    const [googleLogin, setGoogleLogin] = useState(false); 
    const [inputField, setInputField] = useState({
        email: "",
        password: "",
        captcha : "",
        hash : "",
        mobile:""
    });
    const [errField, setErrField] = useState({});
    const [showVerify, setshowVerify] = useState(false);
    const [userId, setUserId] = useState('');
    const history = useNavigate();
    const [reloadCaptcha, setReloadCaptcha] = useState(1);
    const password = captchaData && captchaData.password ? captchaData.password : ""; 
    const [searchParams] = useSearchParams();
    const location = useLocation();

    const validForm = () => {
        let formIsValid = true;
        setErrField({
            captchErr: "",
            captcha: ""
        });
        if (inputField.email === "") {
            formIsValid = false;
            setErrField((prevState) => ({
                ...prevState,
                email: t('VALIDATION.EMAIL'),
            }));
        } else if (!validEmailRegex.test(inputField.email)) {
            formIsValid = false;
            setErrField((prevState) => ({
                ...prevState,
                email: t('VALIDATION.VALID_EMAIL'),
            }));
        }
        if (inputField.password === "") {
            formIsValid = false;
            setErrField((prevState) => ({
                ...prevState,
                password: t('VALIDATION.PASSWORD'),
            }));
        }

        if (!inputField['captcha'].trim()) {
            formIsValid = false;
            setErrField((prevState) => ({
                ...prevState,
                captcha: t('VALIDATION.CAPTCHA_FAILED'),
            }));
            setReloadCaptcha((prev)=> prev+1)
        }
      
        return formIsValid;
    };
    const inputHandler = (e) => {
        setInputField({ ...inputField, [e.target.name]: e.target.value});
    };

    useEffect(() => {
        
        const loggedInUser = JSON.parse(localStorage.getItem('user_information'));  
        if (loggedInUser && loggedInUser.user_data) {
            history("/dashboard")
        }
    },[])

    const submitButton = async () => {
        setLoading(true);
      if (validForm()) { 
            executeGraphQLMutation(Login_User, inputField, client)
                .then((result) => {
                    setLoading(false);
                const loginData = result?.data?.login_user;  
                if (loginData && !loginData?.is_error) {  
                    //DELETE CAPTCHA HASH FROM CACHE 
                    setLoginData(loginData);
                    dispatch(loggedIn(loginData));

                    setUserId(loginData.id);
                    setshowVerify(true); 
                    setReloadCaptcha((prev)=> prev+1)
                    setInputField({ email: inputField.email, password: inputField.password, captcha: ''}) 
                    toast.success(loginData.message); 
                } 
                else{
                    setLoading(false);
                    setReloadCaptcha((prev)=> prev+1)
                    toast.error(loginData.message); 
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    setReloadCaptcha((prev)=> prev+1)
                    toast.error("Invalid Username and Password"); 
                })  
        } else {
            setReloadCaptcha((prev)=> prev+1)
            setLoading(false);
        }
    };
    const handleKeyPress = (e) => {
        if (e.charCode === 13) submitButton();
        return true
    } 

    const captchaFiledHandler = (childData) => {
        if(captchaData.captchaValue){
            setInputField({ ...inputField, captcha: childData,hash :captchaData.captchaValue})
        }
    }
 
  
    useEffect(() => {
      const userDataParam = searchParams.get('userdata'); 
      if (userDataParam) {
        try {
          const parsedData = JSON.parse(decodeURIComponent(userDataParam));  
          if((!parsedData.domain && parsedData.email) || parsedData.domain !== "Invalid"){
            setLoading(true);
            setInputField({ email: parsedData.email, password: "", captcha: ''}); 


            executeGraphQLQuery(GOOGLE_EMAIL(parsedData.email), client)
            .then(async (result) => { 
                const loginData = result?.data?.login_with_email; 
                if (loginData && !loginData?.is_error) {   
                    //DELETE CAPTCHA HASH FROM CACHE 
 
                    localStorage.setItem('loggedIn', '1');
                    localStorage.setItem('user_information', JSON.stringify(loginData)); 
                    
                    setLoginData(loginData);
                    dispatch(loggedIn(loginData));
                    history("/dashboard")
                    setUserId(loginData.id);
                    setshowVerify(true); 
                    setReloadCaptcha((prev)=> prev+1)
                    setInputField({ email: inputField.email, password: inputField.password, captcha: ''}) 
                } 
            }) 
          } else {
            setshowVerify(false); 
            toast.error("Domain is Invalid");
            const urlParams = new URLSearchParams(location.search); 
            if (urlParams.has('userdata')) {
              urlParams.delete('userdata');
              const newUrl = `${location.pathname}`;   
              history(newUrl); 
            }
          }

        } catch (error) {
          console.error('Failed to parse userdata:', error);
        }
      }
    }, [searchParams]);

 
    return (
        <div className="login-form">
            {/* <FullPageLoader show={false} /> */}
            {showVerify ?
                (<VerifyOtp submitButton={submitButton} userId={userId} inputField={inputField} captchaData={ captchaData }  reloadCaptcha={reloadCaptcha} setReloadCaptcha={setReloadCaptcha} setUserId={setUserId} setshowVerify={setshowVerify} LoginData={LoginData} googleLogin={googleLogin} setGoogleLogin={setGoogleLogin}/>)
                :
                <form autoComplete="on">
                    <fieldset className="">
                        <div className={"material " + (errField.email ? "error" : "")}    >
                            <input
                                type="text"
                                placeholder=" "
                                name="email"
                                value={inputField.email}
                                className="form-input"
                                onChange={inputHandler}
                            />
                            <i className="ic-email1 icn-login"></i>
                            <label data-label={t('FORM.EMAIL')} className="form-label"></label>
                            {errField.email && (
                                <span className="erroe-txt">
                                    {errField.email}
                                </span>
                            )}
                        </div>
                    </fieldset>
                    <fieldset className="">
                        <div className={"material " + (errField.password ? "error" : "")}>
                            <input
                                type="password"
                                placeholder=" "
                                name="password"
                                value={inputField.password}
                                className="form-input password-txt"
                                onChange={inputHandler}
                                onKeyPress={handleKeyPress}
                            />
                            <i className="ic-password icn-login"></i>
                            <label data-label={t('FORM.PASSWORD')} className="form-label"></label>
                            {errField.password && (
                                <span className="erroe-txt">
                                    {errField.password}
                                </span>
                            )}
                        </div>
                    </fieldset> 
                    <fieldset className=" captcha-filed">
                        <Captcha onKeyPressCaptcha={ captchaFiledHandler } errField={errField} inputField={inputField} reloadCaptcha={reloadCaptcha}/>
                    
                    </fieldset>  

                    <button type="button" className="btn-primary" onClick={submitButton} disabled={loading}>
                        {t('FORM.LOGIN')}
                    </button>
                    <GoogleLogin setUserId={setUserId} setshowVerify={setshowVerify} setGoogleLogin={setGoogleLogin} setInputField={setInputField}/> 
                 </form> 
            }
        </div>
    );
}

export default EmailUser;
