import React, { useState, useEffect, useRef } from "react";
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';
import { GET_CSV_UPLOAD } from "../../../services/incentivedata.gql";
import { print } from 'graphql';
import { executeCsvUpload } from "../../../common/executeGraphQLQuery";
import downloadIcon from "../../../webroot/images/downloadicon.svg";


const ImageUploader = (props) => {
    const {csvSampleUrl} = props
    const [file, setFile] = useState(null); 
    const [fileUploadType, setFileUploadType] = useState(props.fileUploadType);
    const fileInput = useRef(null);
    const [showUploadedFile, setShowUploadedFile] = useState(false);
    const [uploadedUrl, setUploadedUrl] = useState("")
    const [errorMessage, setErrorMessage] = useState([])
    const result = JSON.parse(localStorage.getItem('user_information'));
    const [disableUpload, setDisableUpload] = useState(false);
 
    useEffect(() => {
        if (props.fileUploadType !== fileUploadType) {
            setFileUploadType(props.fileUploadType);
        }
    }, [props.fileUploadType, fileUploadType]);


    const handleAddImage = (e) => {
        e.preventDefault();
        setDisableUpload(false);
        let selectedFile = e.target.files[0];
        if (selectedFile) {
            if (!["text/csv"].includes(selectedFile.type)) {
                props.setLoading(false);
                toast.error("Please select valid File");
            } else {
                setFile(selectedFile);
                props.uploadStatusHandler({ bulkUploadStatus: true, bulkUploadMsg: "" });
            }
        }
    };

    const openFileUpload = () => {
        document.getElementsByClassName('incentiveCsv')[0].click();
    };

    const handleCancelUpload = () => {
        setFile(undefined);
        setShowUploadedFile(false);
        setUploadedUrl("");
        document.getElementsByClassName('incentiveCsv')[0].value = null;
    };

    const submitUpload = async (event) => {
        event.preventDefault();
        props.setLoading(true);
        setDisableUpload(false);
        const formData = new FormData();

        const operations = JSON.stringify({
            query: print(GET_CSV_UPLOAD()),
            variables: {
                file: null,
                user_id: result?.user_data?.user_id || result?.id,
                email : result?.user_data?.email || result?.email
            }
        });

        const map = JSON.stringify({
            "0": ["variables.file"]
        });

        formData.append('operations', operations);
        formData.append('map', map);
        formData.append('0', file); // append the file
        try {
            executeCsvUpload(formData).then(async (res) => {
                props.setLoading(false);
                if (res?.data?.csv_upload) { 
                    let result = res.data.csv_upload; 
                    if(result?.is_error){
                        toast.error(result?.message);
                        setFile(undefined);
                    } else {
                        if(result?.generated_url){
                            setShowUploadedFile(true);
                            setUploadedUrl(result?.generated_url);
                        } 
                        setErrorMessage(result?.errorMsg); 
                        if(!result?.errorMsg?.length){
                            props.hideModalPdfUploadPopUp();
                            props.showSuccessMsgPopUp();
                        } else {
                            toast.success(result?.message || "");
                            setDisableUpload(true);
                        }
                    } 
                } else { 
                    if(res?.errors?.[0]?.statusCode == 408){
                        toast.error("It is taking too long. Csv will be uploaded and send to you Email.");
                    } else {
                        toast.error(res?.errors?.[0]?.message || '');
                    } 
                }
            })
        } catch (err) {
            props.setLoading(false); 
            toast.error(err || '');
        } 
    };


    let uploadText = file ? (
        <div className="upload-file-name">
            <div className="upload-file-img">
                <span className="suceess-icn">
                    <i className="ic-check"></i>
                </span>
                <h4>{file.name}</h4>
                <i className="ic-clearclose" onClick={(event) => handleCancelUpload(event)}></i>
            </div>
            {
                showUploadedFile && uploadedUrl && errorMessage?.length &&
                <div className="csv-error-listing">
                    <ul>
                        {errorMessage?.map((msg, idx) => {
                            return (
                                <li>
                                    {msg}
                                </li>

                            )
                        })
                        }
                    </ul>
                 
                    <div>
                        <a href={uploadedUrl}> 
                            <span className="csv-download-txt"> Download List</span>
                            <img src={downloadIcon} className="" alt='logo' />
                          
                           </a>
                    </div>
                </div>

            }
            <button className="btn-primary " disabled={disableUpload}>Upload</button>
        </div>
    ) : (
        <div className="upload-txt">
            <h4>{props.t('MESSAGE.DROP_FILE')}</h4>
            <span>{props.t('MESSAGE.XLS_ACCEPTED')}</span>
            <div className="or-txt">or</div>
            <button className="btn-primary file-btn" type="button" onClick={openFileUpload}>
                Browse File
            </button>
        </div>
    );

    return (
        <div style={{ textAlign: "center" }}>
            <form method="post" onSubmit={submitUpload} encType="multipart/form-data">
                <div className="image-uploader-wrapper">
                    <div className={'display-box'}>
                        <div className="icon-text-box">
                            <div className="upload-icon">
                                <i className="ic-file-icon" aria-hidden="true" />
                            </div>
                            <div className="upload-text">
                                {uploadText}
                            </div>
                        </div>
                        <div>
                            <input
                                type="file"
                                ref={fileInput}
                                id="upload-image-input"
                                className="upload-image-input incentiveCsv"
                                accept={".csv "}
                                onChange={handleAddImage}
                                style={file ? { width: 0, height: 0 } : { width: '100%', height: '100%' }}
                            />
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <div className="download-txt">
                        Download input format <a href={csvSampleUrl}>
                            Click Here
                        </a>
                    </div>
                </div>
            </form>
        </div>
    );
};


export default withTranslation('common')(ImageUploader);


