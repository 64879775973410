import Modal from "../../common/Modal";
import FileUpload from "./FileUpload";
import { useEffect, useState } from "react"; 
import FullPageLoader from "../../common/FullPageLoader";
import uploadedIcon from "../../../webroot/images/successSign.svg";
import { useSelector, useDispatch } from "react-redux";
import { getMasterData } from "../../../store/action/allAction";
import { useApolloClient } from '@apollo/client';


function PdfUploader(props) {
    const [loading, setLoading] = useState(false);
    const [fileUplodValue, setPdfUpload] = useState({
        pdfUpload: false,
        pdfUploadStatus: false,
        pdfUploadMsg: false
    })
    const [pdfUploadPopUp, setPdfUploadPopUp] = useState(false);
    const [showSuccessMsg, setShowSuccessMsg] = useState(false);
    const [csvSampleUrl, setCsvSampleUrl] = useState("");
    const dispatch = useDispatch()
    const masterState = useSelector((state) => state.masterdata)
    const client = useApolloClient();


    useEffect(() => {
        if (!Object.keys(masterState?.data)?.length) {
            get_master_data();
        } else {
            let { sample_csv} = masterState?.data; 
            const incentiveSample = sample_csv.find(sample => sample.type === "incentive"); 
            setCsvSampleUrl(incentiveSample?.url)
        }
    }, [masterState])

    const get_master_data = () => {
        dispatch(getMasterData(client));
    }
 
    useEffect(()=>{ 
        if(props.handlePdfUploader && props.handlePdfUploader.pdfUpload){   //SHOW POPUP IF OPENED
            setPdfUpload({
                ...fileUplodValue, pdfUpload: true,type:1
            })
        } 
    }, [props.handlePdfUploader])
 
    /**
     * BULK UPLOAD STATUS HANDLER
     * @param {*} params 
     */
    const uploadStatusHandler = (params) => {
        setPdfUpload({
            ...fileUplodValue, ...params
        })
    }

    const showModalPdfUploadPopUp = () => {
        setPdfUploadPopUp(true)
        document.body.classList.add("overflow-hidden");
    }

    const hideModalPdfUploadPopUp = () => {
        setPdfUploadPopUp(false);
        let filters = {...props.filters};
        props.getDataListing(filters);
        document.body.classList.remove("overflow-hidden");
    }

    const hideSuccessMsgPopUp = () => {
        setShowSuccessMsg(false);
        document.body.classList.remove("overflow-hidden");
    }

    const showSuccessMsgPopUp = () => {
        setShowSuccessMsg(true);
        document.body.classList.add("overflow-hidden");
    } 

    return (

        <>
        <button className="btn-line btn-export" onClick={showModalPdfUploadPopUp} > <span className={""}><i className={"ic-upload"}></i> </span>{"Bulk Upload"}</button>
        { pdfUploadPopUp && 
        <div className="model-popup-outer upload-csv-popup z-index">
             <FullPageLoader show={loading} />
                <Modal show={pdfUploadPopUp} handleClose={()=>hideModalPdfUploadPopUp()}>
                    <div className="modal-header">
                    <h2>Dealer Incentive - Bulk Upload</h2>
                    </div>
                    <div className="modal-body">
                    <FileUpload setLoading={setLoading} uploadStatusHandler={uploadStatusHandler} hideModalPdfUploadPopUp={() => hideModalPdfUploadPopUp()} showSuccessMsgPopUp={showSuccessMsgPopUp} csvSampleUrl={csvSampleUrl}/>

                    </div>
                </Modal>
            </div>
            } 

      { showSuccessMsg && 
        <div className="model-popup-outer csv-success-msg-popup">
             <FullPageLoader show={loading} />
                <Modal show={showSuccessMsg} handleClose={()=>hideSuccessMsgPopUp()}> 
                    <div className="modal-body">
                        <img src={uploadedIcon} className="" alt='logo' />
                         <h2>Bulk Incentive has been Uploaded Successfully.</h2>
                    </div>      
                </Modal>
            </div>
            } 
        </>
        
  );
}

export default PdfUploader;