import React, { useEffect,useState } from 'react';
import { useApolloClient } from '@apollo/client'; 
import DealerConfigHistory from "./DealerConfigHistory";
import { Tab, Nav } from "react-bootstrap";
import CurrencyInputField from "../../common/CurrencyInputField";
import InputField from "../../common/InputField";
import { executeGraphQLQuery,executeGraphQLMutation } from "../../../../src/common/executeGraphQLQuery"; 
import { GET_INCENTIVE_DATA,SAVE_INCENTIVE_DATA } from "../../../services/incentivedata.gql";  
import FullPageLoader from '../../common/FullPageLoader';
import * as HELPER from "../../../config/helper";
import { toast } from "react-toastify";
import ConfirmationModal from "../../common/ConfirmationModal";



const DealerConfiguration = () => {
    const [incentives, setIncentives] = useState([]);
    const [incentivesAll, setIncentivesAll] = useState({});
    const [activeTab, setActiveTab] = useState(1); 
    const [viewHistory, setViewHistory] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorValue, setErrorValue] = useState(true);
    const [errors, setErrors] = useState({});
    const client = useApolloClient();
    const [confimationPopUp, setConfirmationPopup] = useState({ status: false, msg: '',type:'' });
    const keysToRemove = ['scheme_type', 'status',"__typename"];
    const deleteShow=1;
    const USER_DATA = JSON.parse(localStorage.getItem("user_information")); 


    useEffect(() => { 
        if(!Object.keys(incentivesAll).length){
            getDealerIncentiveConfigList();
        }else{
          getIncentiveData(incentivesAll,activeTab);
           
        }
        
    },[incentivesAll]);

    const changeActiveTab =(activetab) => {
        setActiveTab(activetab);
        setErrors({});
        setErrorValue(true);
        getIncentiveData(incentivesAll,activetab);
    }

    const getIncentiveData =(result,activeTab) => {
        let incentiveData=[];
        let defaultdata=[{disbursal_from:null, disbursal_to:null, gmv_from:null, gmv_to:null, incentive_amount:"",row:1}]
        if(activeTab===1){
            incentiveData=(result?.monthly_list.length)?result?.monthly_list:defaultdata;
        }else if(activeTab===2){
            incentiveData=(result?.quarterly_list.length)?result?.quarterly_list:defaultdata;
        }else{
            incentiveData=(result?.annual_list.length)?result?.annual_list:defaultdata;
        }
        incentiveData=incentiveData.map(({ [keysToRemove[0]]: _, [keysToRemove[1]]: __,[keysToRemove[2]]: ___, ...rest })=>rest);
        setIncentives(incentiveData);
    }

    const getDealerIncentiveConfigList = async() => {
        setLoading(true);
        executeGraphQLQuery(GET_INCENTIVE_DATA(), client).then(response => { 
            setLoading(false);
            if(response && response?.data && response.data?.scheme_list) {
                let result = response.data.scheme_list;  
                setIncentivesAll(result);
                getIncentiveData(result,activeTab);
            }
        }).catch((error) => {
        
        });
    };


    const addIncentiveSlab = () => {
        let row=incentives.length+1;
        let dealerIncvObj={ disbursal_from:null, disbursal_to:null, gmv_from:null, gmv_to:null, incentive_amount:"",row:row}
        setIncentives([...incentives,dealerIncvObj]);
    };

    const viewHistoryPopup = (state) => {
        setViewHistory(state);
    };

    const handleChange = (index, field, value) => {
        value=(value)?(['disbursal_from','disbursal_to'].includes(field))? +value: +HELPER.removeCurrencyFormatting(value):null;
        const updatedIncentives = [...incentives];
        const updatedInc = [...incentives];
        updatedInc[index][field]=+value;
        updatedIncentives[index][field] = value
        setIncentives(updatedIncentives);
       
        if(['disbursal_from','disbursal_to','gmv_from','gmv_to','incentive_amount'].includes(field)){
            checkDisbursalRangeValidation(index, field, value,updatedInc);
        }
    };

    
    const checkDisbursalRangeValidation = (index, field, value,updatedIncentives) => {
        let error=errors;
        let tempErrors = {...error};
        let errorvalue=true;
        
        let currentrow=updatedIncentives[index];
        
        if(field==="incentive_amount" && value!=""){
            delete tempErrors["incentive_amount_" + index]; 
        }
        if(updatedIncentives.length>1){
            let disbursal = updatedIncentives.filter((el)=>el.disbursal_from!==null && el.disbursal_to!==null).map((obj) => ({disbursal_from:Number(obj.disbursal_from),disbursal_to:Number(obj.disbursal_to),row:Number(obj.row)}));
            disbursal.sort((a, b) => a["disbursal_from"] - b["disbursal_from"]);

            if(disbursal.length){
                disbursal=disbursal.filter((el)=>el.row!==currentrow.row);
                
                for (let i = 0; i <= disbursal.length-1; i++) {
                    if(field==='disbursal_from'  && ((currentrow['disbursal_to']!==null && +value>=currentrow['disbursal_to']) || (value>=disbursal[i]['disbursal_from'] && value<=disbursal[i]['disbursal_to']))){
                        errorvalue = false;
                        tempErrors["disbursal_from_" + index] = "Please enter correct disbursal range";
                        break;
                    }else if(field==='disbursal_from') {
                        errorvalue = true;
                        delete tempErrors["disbursal_from_" + index];  
                        delete tempErrors["gmv_from_" + index]; 
                        delete tempErrors["gmv_to_" + index]; 
                    }
                    if(field==='disbursal_to'  && ((currentrow['disbursal_from']!==null && +value<=currentrow['disbursal_from']) || (value>=disbursal[i]['disbursal_from'] && value<=disbursal[i]['disbursal_to']))){
                        errorvalue = false;
                        tempErrors["disbursal_to_" + index] = "Please enter correct disbursal range";
                        break;
                    }else if(field==='disbursal_to') {
                        errorvalue = true;
                        delete tempErrors["disbursal_to_" + index];  
                        delete tempErrors["gmv_from_" + index]; 
                        delete tempErrors["gmv_to_" + index]; 
                    }
                }

            }else{
                if(field==='disbursal_from' && disbursal.length  && disbursal[0]['disbursal_to']!==null && +value>=disbursal[0]['disbursal_to']){
                    errorvalue = false;
                    tempErrors["disbursal_from_" + index] = "Please enter correct disbursal range";
                }else if(field==='disbursal_from') {
                    errorvalue = true;
                    delete tempErrors["disbursal_from_" + index];  
                    delete tempErrors["gmv_from_" + index]; 
                    delete tempErrors["gmv_to_" + index]; 
                }
                if(field==='disbursal_to' && disbursal.length && disbursal[0]['disbursal_from']!==null && (+value<=disbursal[0]['disbursal_from'])){
                    errorvalue = false;
                    tempErrors["disbursal_to_" + index] = "Please enter correct disbursal range";
                }else if(field==='disbursal_to'){
                    errorvalue = true;
                    delete tempErrors["disbursal_to_" + index]; 
                    delete tempErrors["gmv_from_" + index]; 
                    delete tempErrors["gmv_to_" + index]; 
                }
            }

            let gmv = updatedIncentives.filter((el)=>el.gmv_from!==null && el.gmv_to!==null).map((obj) => ({gmv_from:Number(obj.gmv_from),gmv_to:Number(obj.gmv_to),row:Number(obj.row)}));
            gmv.sort((a, b) => a["gmv_from"] - b["gmv_from"]);
          
            if(gmv.length>1){
                gmv=gmv.filter((el)=>el.row!==currentrow.row);
                
                for (let i = 0; i <= gmv.length-1; i++) {
                    if(field==='gmv_from'  && ((currentrow['gmv_to']!==null && +value>=currentrow['gmv_to']) || (value>=gmv[i]['gmv_from'] && value<=gmv[i]['gmv_to']))){
                        errorvalue = false;
                        tempErrors["gmv_from_" + index] = "Please enter correct gmv range";
                        break;
                    }else if(field==='gmv_from') {
                        errorvalue = true;
                        delete tempErrors["gmv_from_" + index]; 
                        delete tempErrors["disbursal_to_" + index]; 
                        delete tempErrors["disbursal_from_" + index]; 
                    }
                    if(field==='gmv_to'  && ((currentrow['gmv_from']!==null && +value<=currentrow['gmv_from']) || (value>=gmv[i]['gmv_from'] && value<=gmv[i]['gmv_to']))){
                        errorvalue = false;
                        tempErrors["gmv_to_" + index] = "Please enter correct gmv range";
                        break;
                    }else if(field==='gmv_to') {
                        errorvalue = true;
                        delete tempErrors["gmv_to_" + index]; 
                        delete tempErrors["disbursal_to_" + index]; 
                        delete tempErrors["disbursal_from_" + index]; 
                    }
                }

            }else{
                if(field==='gmv_from' && gmv.length  && gmv[0]['gmv_from']!==null && +value>=gmv[0]['gmv_to']){
                    errorvalue = false;
                    tempErrors["gmv_from_" + index] = "Please enter correct gmv range";
                }else if(field==='gmv_from') {
                    errorvalue = true;
                    delete tempErrors["gmv_from_" + index];  
                    delete tempErrors["disbursal_to_" + index]; 
                    delete tempErrors["disbursal_from_" + index];
                }
                if(field==='gmv_to' && gmv.length && gmv[0]['gmv_from']!==null && (+value<=gmv[0]['gmv_from'])){
                    errorvalue = false;
                    tempErrors["gmv_to_" + index] = "Please enter correct gmv range";
                }else if(field==='gmv_to'){
                    errorvalue = true;
                    delete tempErrors["gmv_to_" + index];
                    delete tempErrors["disbursal_to_" + index]; 
                    delete tempErrors["disbursal_from_" + index]; 
                }
            }
                
                // let disbursal = updatedIncentives.filter(el=>el.disbursal_from!==null && el.disbursal_to!==null).map((obj) => ({disbursal_from:Number(obj.disbursal_from),disbursal_to:Number(obj.disbursal_to),row:Number(obj.row)}));
               
                // disbursal.sort((a, b) => a["disbursal_from"] - b["disbursal_from"]);
        
                // if(disbursal.length){
                //     for (let i = 0; i <= disbursal.length-1; i++) {
                //         if(i===disbursal.length-1){
                //             if(disbursal[i].disbursal_to <= disbursal[i].disbursal_from || (disbursal[i].disbursal_from >= disbursal[i].disbursal_to)) {
                //                 errorvalue = false;
                //                 tempErrors["disbursal_from_" +  (disbursal[i].row-1)] = "Please enter correct disbursal range";
                //                 tempErrors["disbursal_to_" +  (disbursal[i].row-1)] = "Please enter correct disbursal range";
                //             }else{
                //                 errorvalue = true;
                //                 delete tempErrors["disbursal_from_" +  (disbursal[i].row-1)]; 
                //                 delete tempErrors["disbursal_to_" +  (disbursal[i].row-1)];
                //             }
                //         }else{
                //             if ((disbursal[i].disbursal_to >= disbursal[i + 1].disbursal_from) || (disbursal[i].disbursal_to <= disbursal[i].disbursal_from) || (disbursal[i+1].disbursal_to <= disbursal[i+1].disbursal_from) || (disbursal[i+1].disbursal_from <= disbursal[i].disbursal_from) ) {
                //                 errorvalue = false;
                //                 tempErrors["disbursal_from_" + (disbursal[i].row-1)] = "Please enter correct disbursal range";
                //                 tempErrors["disbursal_to_" +  (disbursal[i].row-1)] = "Please enter correct disbursal range";
                //             }else{
                //                 errorvalue = true;
                //                 delete tempErrors["disbursal_from_" +  (disbursal[i].row-1)]; 
                //                 delete tempErrors["disbursal_to_" +  (disbursal[i].row-1)];
                //             }
                //         }
                //     }    
                // }
           
                // let gmv = updatedIncentives.filter(el=>el.gmv_from!==null && el.gmv_to!==null).map((obj) => ({gmv_from:Number(obj.gmv_from),gmv_to:Number(obj.gmv_to),row:Number(obj.row)}));
                
                // gmv.sort((a, b) => a["gmv_from"] - b["gmv_from"]);
                // if(gmv.length>1){
                //     for (let i = 0; i <= gmv.length-1; i++) {
                //         if(i===gmv.length-1){
                //             if(gmv[i].gmv_to <= gmv[i].gmv_from || (gmv[i].gmv_from >= gmv[i].gmv_to)) {
                //                 errorvalue = false;
                //                 tempErrors["gmv_from_" + (gmv[i].row-1)] = "Please enter correct gmv range";
                //                 tempErrors["gmv_to_" + (gmv[i].row-1)] = "Please enter correct gmv range";
                //             }else{
                //                 errorvalue = true;
                //                 delete tempErrors["gmv_from_" + (gmv[i].row-1)]; 
                //                 delete tempErrors["gmv_to_" + (gmv[i].row-1)];
                //             }

                //         }else{
                //             if ((gmv[i].gmv_to >= gmv[i + 1].gmv_from) || (gmv[i].gmv_to <= gmv[i].gmv_from) || (gmv[i+1].gmv_to <= gmv[i+1].gmv_from) || (gmv[i+1].gmv_from <= gmv[i].gmv_from)) {
                //                 errorvalue = false;
                //                 tempErrors["gmv_from_" + (gmv[i].row-1)] = "Please enter correct gmv range";
                //                 tempErrors["gmv_to_" + (gmv[i].row-1)] = "Please enter correct gmv range";
                //             }else{
                //                 errorvalue = true;
                //                 delete tempErrors["gmv_from_" + (gmv[i].row-1)]; 
                //                 delete tempErrors["gmv_to_" + (gmv[i].row-1)];
                //             }
                //         } 
                        
                //     }    
                // }else if(gmv.length){
                //     if(gmv[0].gmv_to <= gmv[0].gmv_from || (gmv[0].gmv_from >= gmv[0].gmv_to)) {
                //         errorvalue = false;
                //         tempErrors["gmv_from_" + (gmv[0].row-1)] = "Please enter correct gmv range";
                //         tempErrors["gmv_to_" + (gmv[0].row-1)] = "Please enter correct gmv range";
                //     }else{
                //         errorvalue = true;
                //         delete tempErrors["gmv_from_" + (gmv[0].row-1)]; 
                //         delete tempErrors["gmv_to_" + (gmv[0].row-1)];
                //     }
                // }
           
        }else{
            if(field==='disbursal_from' && updatedIncentives[index]['disbursal_to']!==null && (+value>=updatedIncentives[index]['disbursal_to'])){
                errorvalue = false;
                tempErrors["disbursal_from_" + index] = "Please enter correct disbursal range";
            }else if(field==='disbursal_from') {
                errorvalue = true;
                delete tempErrors["disbursal_from_" + index]; 
                delete tempErrors["gmv_from_" + index]; 
                delete tempErrors["gmv_to_" + index]; 
            }
            if(field==='disbursal_to' && updatedIncentives[index]['disbursal_from']!==null && (+value<=updatedIncentives[index]['disbursal_from'])){
                errorvalue = false;
                tempErrors["disbursal_to_" + index] = "Please enter correct disbursal range";
            }else if(field==='disbursal_to'){
                errorvalue = true;
                delete tempErrors["disbursal_to_" + index]; 
                delete tempErrors["gmv_from_" + index]; 
                delete tempErrors["gmv_to_" + index]; 
            
            }
            if(field==='gmv_from' && updatedIncentives[index]['gmv_to']!==null && (+value>=updatedIncentives[index]['gmv_to'])){
                errorvalue = false;
                tempErrors["gmv_from_" + index] = "Please enter correct gmv range";
            }else if(field==='gmv_from'){
                errorvalue = true;
                delete tempErrors["gmv_from_" + index];
                delete tempErrors["disbursal_to_" + index]; 
                delete tempErrors["disbursal_from_" + index]; 
            }
            if(field==='gmv_to' && updatedIncentives[index]['gmv_from']!==null && (+value<=updatedIncentives[index]['gmv_from'])){
                errorvalue = false;
                tempErrors["gmv_to_" + index] = "Please enter correct gmv range";
            }else if(field==='gmv_to'){
                errorvalue = true;
                delete tempErrors["gmv_to_" + index];
                delete tempErrors["disbursal_to_" + index]; 
                delete tempErrors["disbursal_from_" + index]; 
            }
        }
        
        if(Object.keys(tempErrors).length){
            setErrorValue(false);
            setErrors(tempErrors);
        }else{
            setErrorValue(true);
            setErrors({});
        }
        
        
    }

    const isFormValid = () => {
        let formIsValid = true;
        setErrors({});
        let tempErrors = {};
        incentives.forEach((data, i) => {
            if(data.disbursal_from===null && data.disbursal_to===null && data.gmv_from===null && data.gmv_to===null  ){
                let message="Please enter disbursal or gmv";
                tempErrors["disbursal_from_" + i] = message;
                tempErrors["disbursal_to_" + i] = message;
                tempErrors["gmv_from_" + i] = message;
                tempErrors["gmv_to_" + i] = message;
                formIsValid = false;
            }if(data.disbursal_from!==null &&  data.disbursal_to===null && data.gmv_from===null &&  data.gmv_to===null || (data.disbursal_from!==null &&  data.disbursal_to===null && data.gmv_from!==null &&  data.gmv_to!==null)){
                tempErrors["disbursal_to_" + i] = "Disbursal to is required";
                formIsValid = false; 
            }if(data.disbursal_to!==null &&  data.disbursal_from===null && data.gmv_from===null &&  data.gmv_to===null){
                tempErrors["disbursal_from_" + i] = "Disbursal from is required";
                formIsValid = false; 
            }else if(data.gmv_from!==null &&  data.gmv_to===null && data.disbursal_to===null &&  data.disbursal_from===null){
                tempErrors["gmv_to_" + i] = "Gmv to is required";
                formIsValid = false; 
            }else if(data.gmv_to!==null &&  data.gmv_from===null && data.disbursal_to===null &&  data.disbursal_from===null){
                tempErrors["gmv_from_" + i] = "Gmv from is required";
                formIsValid = false; 
            }
            if(data.incentive_amount==='' || data.incentive_amount===undefined || data.incentive_amount===null){
                tempErrors["incentive_amount_" + i] = "Incentive amount is required"
                formIsValid = false; 
            }else{
                delete tempErrors["incentive_amount_" + i];
            }
        });
        setErrors(tempErrors);
        return formIsValid;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if(errorValue){
            let isFormvalid=isFormValid();
            if (isFormvalid) {
                setLoading(true);
                let incentiveparams={
                    schemeInput:incentives,
                    scheme_type:activeTab,
                    created_by:USER_DATA?.id
                }
                executeGraphQLMutation(SAVE_INCENTIVE_DATA,incentiveparams, client).then(response => { 
                    setLoading(false);
                    if(response && response.data && response?.data?.save_scheme && !response?.data?.save_scheme?.is_error) {
                        toast.success(response.data.save_scheme.message);
                        getDealerIncentiveConfigList();
                    }else{
                        toast.error(response.data.save_scheme.message)
                    }
                }).catch((error) => {
                });
            } 
        }
           
    }
    
    const showModalConfimationPopUp = (params) => {
        let { status } = params 
        setConfirmationPopup(params)
        document.body.classList.remove("overflow-hidden");
        if (status) {
            document.body.classList.add("overflow-hidden");
        }
    }
    const confirmationModalResponse = (params) => {
        if(params.response){ 
            let index=params?.type?.index;
            let updatedIncentives = JSON.parse(JSON.stringify(incentives));
            updatedIncentives.splice(index, 1);
            setIncentives(updatedIncentives);
            if(Object.keys(errors).length){
                let tempErrors = {...errors};
                delete tempErrors["disbursal_from_" + index];
                delete tempErrors["disbursal_to_" + index];
                delete tempErrors["gmv_from_" + index];
                delete tempErrors["gmv_to_" + index];
                delete tempErrors["incentive_amount_" + index];
                setErrors(tempErrors);
            }
        }
        showModalConfimationPopUp(params);
    }

    return (
        <div className="incentive-configuration">
            <FullPageLoader show={loading} />
            <div className="dealer-outer">
                <div className="container-fluid">
                    <div className="top-heading">
                        <h1>UCF Dealer Incentive Configuration</h1>
                    </div>

                    <div className="dealer-configuration-outer">
                        <Tab.Container id="left-tabs-example" defaultActiveKey={'1'} activeTab={activeTab} >
                            {/* MAIN HEADING */}
                            <Nav variant="pills" className="flex-column tab-line dealer-configuration-tab">
                                <div className="tab-list">
                                    <Nav.Item onClick={() =>{changeActiveTab(1)}}>
                                        <Nav.Link eventKey="1">Monthly Incentive ({incentivesAll && incentivesAll.monthly_list && incentivesAll?.monthly_list?.length || 1})</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item onClick={() => {changeActiveTab(2)}}>
                                        <Nav.Link eventKey="2">Quarterly Incentive ({incentivesAll && incentivesAll.quarterly_list && incentivesAll?.quarterly_list?.length ||1})</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item onClick={() => {changeActiveTab(3)}}>
                                        <Nav.Link eventKey="3">Annual Incentive ({incentivesAll && incentivesAll.annual_list && incentivesAll?.annual_list?.length || 1})</Nav.Link>
                                    </Nav.Item>
                                </div>

                                <div className='btn-line-auto-width'>
                                <button className="btn-line btn-auto-width m-sm-l" onClick={() => viewHistoryPopup(true)}><i className='ic-history m-sm-r'></i>View History</button>
                                <button className="btn-line btn-auto-width  m-sm-l" onClick={addIncentiveSlab}>+ Add Incentive Slab</button> 
                                </div>

                            </Nav>
                            
                            <Tab.Content key={activeTab} id={activeTab}>
                                <Tab.Pane eventKey={activeTab}> 
                                    <div className='payout-summary-tabs'>
                                        <Tab.Container id="left-tabs-example">
                                            <div>
                                                <ul className="financier-group-config">
                                                    {incentives.length > 0 && incentives.map((inc, index) =>
                                                        <li key={index}>
 
                                                            <span className="financer-group-label">Disbursed From</span> 
                                                            <fieldset className="input m-md-r">
                                                            
                                                                <div className="material">
                                                                    <InputField
                                                                        inputProps={{
                                                                            id: "disbursal_from_" + index,
                                                                            type: "text",
                                                                            name: "disbursal_from",
                                                                            placeholder: " ",
                                                                            value:(inc.disbursal_from!=null)?inc.disbursal_from:'',
                                                                            className: "form-input",
                                                                            disabled: false,
                                                                            maxLength: 16
                                                                        }}
                                                                        onChange={(e) => { handleChange(index, 'disbursal_from', e.target.value) }}
                                                                    />
                                                                </div>
                                                                {
                                                                    (errors && errors["disbursal_from_" + index]) ? <span className="error show">{errors["disbursal_from_" + index]}</span> : ''
                                                                }
                                                            </fieldset> 
                                                            <span className="form-label">Disbursed to</span> 
                                                            <fieldset className="input m-md-r">
                                                                <div className="material">
                                                                    <InputField
                                                                        inputProps={{
                                                                            id: "disbursal_to_" + index,
                                                                            type: "text",
                                                                            name: "disbursal_to",
                                                                            placeholder: " ",
                                                                            value: (inc.disbursal_to!=null)?inc.disbursal_to:'',
                                                                            className: "form-input",
                                                                            disabled: false,
                                                                            maxLength: 16
                                                                        }}
                                                                        onChange={(e) => { handleChange(index, 'disbursal_to', e.target.value) }}
                                                                    />
                                                                </div>
                                                                {
                                                                    (errors && errors["disbursal_to_" + index]) ? <span className="error show">{errors["disbursal_to_" + index]}</span> : ''
                                                                }
                                                            </fieldset>
                                                            <span className="form-label">GMV From</span> 
                                                            <fieldset className="input m-md-r">
                                                                <div className="material">
                                                                    <CurrencyInputField
                                                                        inputProps={{
                                                                            id: "gmv_from_" + index,
                                                                            type: "text",
                                                                            name: "gmv_from",
                                                                            placeholder: " ",
                                                                            value: (inc.gmv_from!==null)?inc.gmv_from:'',
                                                                            className: "form-input",
                                                                            disabled: false,
                                                                            maxLength: 16
                                                                        }}
                                                                        onChange={(e) => { handleChange(index, 'gmv_from', e.target.value) }}
                                                                    /> 
                                                                </div>
                                                                {
                                                                    (errors && errors["gmv_from_" + index]) ? <span className="error show">{errors["gmv_from_" + index]}</span> : ''
                                                                }
                                                            </fieldset>
                                                            <span className="form-label">GMV To</span> 
                                                            <fieldset className="input m-md-r">
                                                                <div className="material">
                                                                    <CurrencyInputField
                                                                        inputProps={{
                                                                            id: "gmv_to_" + index,
                                                                            type: "text",
                                                                            name: "gmv_to",
                                                                            placeholder: " ",
                                                                            value: (inc.gmv_to!==null)?inc.gmv_to:'' ,
                                                                            className: "form-input",
                                                                            disabled: false,
                                                                            maxLength: 16
                                                                        }}
                                                                        onChange={(e) => { handleChange(index, 'gmv_to', e.target.value) }}
                                                                    /> 
                                                                </div>
                                                                {
                                                                    (errors && errors["gmv_to_" + index]) ? <span className="error show">{errors["gmv_to_" + index]}</span> : ''
                                                                }
                                                            </fieldset>
                                                            <span className="form-label">Incentive</span> 
                                                            <fieldset className="input m-md-r">
                                                           
                                                                <div className="material">
                                                                    <CurrencyInputField
                                                                        inputProps={{
                                                                            id: "incentive_amount_" + index,
                                                                            type: "text",
                                                                            name: "incentive_amount",
                                                                            placeholder: " ",
                                                                            value: inc.incentive_amount || "",
                                                                            className: "form-input",
                                                                            disabled: false,
                                                                            maxLength: 16
                                                                        }}
                                                                        onChange={(e) => { handleChange(index, 'incentive_amount', e.target.value) }}
                                                                    /> 
                                                                </div>
                                                                {
                                                                    (errors && errors["incentive_amount_" + index]) ? <span className="error show">{errors["incentive_amount_" + index]}</span> : ''
                                                                }
                                                            </fieldset>
                                                            {index === incentives.length - 1 && incentives.length>deleteShow && (
                                                                <ul className='delete-row-icon'>
                                                                    <li  onClick={() => showModalConfimationPopUp({ status: true, msg:"Are you sure you want to delete this incentive Scheme?",type:{index:index}})}>
                                                                        <i className='ic-delete-icn'></i>
                                                                    </li>
                                                                </ul>
                                                            )}
                                                        </li>)
                                                  
                                                    }
                                                </ul>
                                            </div>
                                        </Tab.Container>
                                    </div>
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                
                <div className="p-lg-t p-lg-b">
                    {
                       (incentives.length)?<button className="btn-line btn-primary" onClick={(e) => handleSubmit(e)} >Save Changes</button>
                       :
                        <button type="button" className="btn-primary" disabled>Save Changes</button>

                    }
                   
                </div>
                    </div>
                </div>
            </div>
  
            
            {
                viewHistory && <DealerConfigHistory viewHistory={viewHistory} viewHistoryPopup={(e) => viewHistoryPopup(e)}></DealerConfigHistory>
            }

           <ConfirmationModal confirmationData={{ show: confimationPopUp.status, confirmationText: confimationPopUp.msg,type:confimationPopUp.type }} confirmationModalResponse={confirmationModalResponse} />
        </div>

    );
};



export default DealerConfiguration;
